import { useState } from 'react'
import { cx } from '@/utils/strings'
import RichText from '@/components/ui/rich-text'
import Image from '@/components/ui/image'
import AngleIcon from '@/icons/bwi/angle-up.svg'
import Collapsible from '@/components/ui/collapsible'

export default function Faq({ question, answer }: any): JSX.Element {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <div className="rounded-4xl bg-gray-100 w-full mb-2">
      <button
        type="button"
        className="flex gap-6 w-full justify-between items-start text-left py-5 px-6 text-lg lg:text-xl focus:outline-none focus-visible:ring focus-visible:ring-gray-300 focus-visible:ring-opacity-75 lg:px-10"
        onClick={() => setIsOpen(!isOpen)}
      >
        <span>{question}</span>
        <AngleIcon className={cx('w-4 flex-shrink-0 mt-1 transition duration-200', isOpen ? '' : `rotate-180`)} />
      </button>
      <Collapsible className="px-6 lg:px-10" isOpen={isOpen}>
        <RichText
          body={answer as any}
          renderImage={(ref) => (
            <div className="pt-6 pb-12 m-auto w-1/2 [&_svg]:w-full [&_figure]:!w-full [&_img]:!w-full">
              <Image image={ref} />
            </div>
          )}
        />
      </Collapsible>
    </div>
  )
}
