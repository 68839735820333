import { cx, slugify } from '@/utils/strings'
import Faq from '@/components/ui/faq'

import RichText from '@/components/ui/rich-text'
import colors from '@/constants/colors'
import type { Entry } from 'contentful'
import type { TypeSectionFaqsSkeleton } from '@/types/ctf'
import { useLiveUpdates } from '@/utils/live-preview'

type SectionFaqsEntry = Entry<TypeSectionFaqsSkeleton, 'WITHOUT_UNRESOLVABLE_LINKS'>

type SectionFaqsProps = SectionFaqsEntry['fields'] & {
  pageColor?: string
  entryId?: string
}

export default function Faqs({ pageColor, entryId, ...fields }: SectionFaqsProps) {
  const {
    fields: { internalName, headline, body, style, backgroundColor, faqs },
  } = useLiveUpdates({ fields, sys: { id: entryId } })

  const { white, primaryBlue, indigoBlue, lightGray } = colors

  const faqsChunk1 = faqs?.slice(0, Math.ceil(faqs.length / 2)) || []
  const faqsChunk2 = faqs?.slice(Math.ceil(faqs.length / 2), faqs.length) || []

  return (
    <section className="max-w-8xl px-5 lg:px-9 mx-auto" id={slugify(internalName)}>
      <div
        className={cx(
          'rounded-4xl',
          backgroundColor !== pageColor && backgroundColor !== 'None' && 'py-10 px-5 lg:py-16',
          (pageColor === null || pageColor === white) &&
            (backgroundColor === white || backgroundColor === 'None') &&
            '!py-0 !px-0',
          backgroundColor === white && `bg-white`,
          backgroundColor === primaryBlue && `bg-primaryBlue`,
          backgroundColor === indigoBlue && `bg-indigoBlue`,
          backgroundColor === lightGray && `bg-gray-100`
        )}
      >
        {(headline || body) && (
          <div
            className={cx(
              'max-w-4xl mx-auto text-indigoBlue px-5 mb-5 lg:mb-12',
              (backgroundColor !== pageColor && backgroundColor !== 'None') ||
                (pageColor === backgroundColor && 'px-0'),
              (pageColor === null || pageColor === white) &&
                (backgroundColor === white || backgroundColor === 'None') &&
                '!py-0 !px-0',
              (pageColor === indigoBlue || pageColor === primaryBlue) && `text-white`,
              (backgroundColor === indigoBlue || backgroundColor === primaryBlue) && `text-white`,
              (backgroundColor === white || backgroundColor === lightGray) && `text-indigoBlue`
            )}
          >
            {headline && <h3 className="text-center font-normal">{headline}</h3>}
            {body && (
              <div className="text-center">
                <RichText body={body as any} />
              </div>
            )}
          </div>
        )}
        {faqs && (
          <div
            className={cx(
              'mx-auto rounded-4xl',
              style === 'Constrained' ? 'mt-8 px-5 max-w-3xl' : 'max-w-8xl',
              backgroundColor !== pageColor && backgroundColor !== 'None' && 'px-0',
              pageColor === backgroundColor && 'px-0',
              (pageColor === null || pageColor === white) &&
                (backgroundColor === white || backgroundColor === 'None') &&
                '!py-0 !px-0'
            )}
          >
            {style === 'Two Column' ? (
              <div className="grid md:grid-cols-2 gap-4">
                <div>
                  {faqsChunk1.map((faq) => (
                    <Faq key={faq?.sys.id} {...faq?.fields} />
                  ))}
                </div>
                <div>
                  {faqsChunk2.map((faq) => (
                    <Faq key={faq?.sys.id} {...faq?.fields} />
                  ))}
                </div>
              </div>
            ) : (
              faqs.map((faq) => <Faq key={faq?.sys.id} {...faq?.fields} />)
            )}
          </div>
        )}
      </div>
    </section>
  )
}
